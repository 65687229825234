import React from 'react';

import CollectionTile from '../CollectionTile/CollectionTile';

import styles from './CollectionList.module.scss';

const CollectionList = ({ collections, section, title, user }) => (
  <>
    {title && <h1 className={styles.title}>{title}</h1>}
    <div className={styles.container}>
      {(collections || [])
        // different graphql queries provide the data with or without a .node parent
        .map(collection => ({
          ...(collection.node ? collection.node : collection),
          count: collection.count,
        }))
        .filter(collection => collection && collection.data.title.length > 0)
        .map(({ count, id, data: collection }) => {
          return (
            <div className={styles.item} key={id}>
              <CollectionTile
                count={count}
                section={section}
                user={user}
                {...collection}
              />
            </div>
          );
        })}
    </div>
  </>
);

CollectionList.propTypes = {};

export default CollectionList;
