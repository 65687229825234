import React from 'react';
import { Router } from '@reach/router';
import UsersTemplate from '../templates/users/users';

const Users = () => {
  return (
    <Router basepath="/users">
      <UsersTemplate path="/" />
      <UsersTemplate path="/:slug" />
    </Router>
  );
};

export default Users;
