/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../../components/furniture/Layout/Layout';

import generateTitle from '../../utils/generateTitle';

import { useFirebase } from 'gatsby-plugin-firebase';
import CollectionList from '../../components/collections/CollectionList/CollectionList';

const UsersTemplate = ({ location, slug }) => {
  const [userData, setUserData] = useState(null);
  const [userCollectionsData, setUserCollectionsData] = useState(null);

  const data = useStaticQuery(graphql`
      query {
          collections: allAirtable(
              sort: { fields: [data___date], order: DESC }
              filter: { table: { eq: "collections" } }
          ) {
              edges {
                  node {
                      id
                      data {
                          title
                          slug
                          image {
                              localFiles {
                                  childImageSharp {
                                      fluid(maxWidth: 600, quality: 40) {
                                          ...GatsbyImageSharpFluid_noBase64
                                      }
                                  }
                              }
                          }
                          owned_actions {
                              id
                          }
                      }
                  }
              }
          }
      }
  `);

  const {
    collections: { edges: collections },
  } = data;

  let pageTitle = generateTitle(slug, 'Users');

  useFirebase(firebase => {
    const db = firebase.firestore();

    const getUserData = async () => {
      const userName = await db
        .collection('userNames')
        .doc(slug)
        .get();

      if (userName.exists) {
        const userId = userName.data().userId;

        if (!!userId) {
          const userData = await db
            .collection('users')
            .doc(userId)
            .get();
          const userCollectionData = await db.collection('userCollections').doc(userId).get();

          setUserData(userData.data());
          setUserCollectionsData(userCollectionData.data());

        }
      }
    };
    getUserData();
  }, []);

  if (!userData) {
    return (
      <Layout location={location} pageTitle={pageTitle}>
        <h1>Loading...</h1>
      </Layout>
    );
  }

  pageTitle = generateTitle(userData.userName, 'Users');

  const joined = new Date(userData.created.seconds * 1000);
  const userCollections = Object.keys(userCollectionsData || {});

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>{userData.userName}</h1>

      <p>Joined: {joined.toLocaleDateString()}</p>

      <h1>Collections</h1>

      <CollectionList
        collections={collections.filter(
            collection => userCollections.indexOf(collection.node.id) > -1
        ).map(collection => {
          return {
          ...collection,
          count: userCollectionsData[collection.node.id].actions.got.uniqueCount,
        }})}
        user={userData.slug}
      />
    </Layout>
  );
};

export default UsersTemplate;
